define("@ember/string/index", ["exports", "@ember/string/lib/string_registry", "@ember/-internals/utils", "@ember/debug", "@ember/-internals/glimmer"], function (_exports, _string_registry, _utils, _debug, _glimmer) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "_getStrings", {
    enumerable: true,
    get: function () {
      return _string_registry.getStrings;
    }
  });
  Object.defineProperty(_exports, "_setStrings", {
    enumerable: true,
    get: function () {
      return _string_registry.setStrings;
    }
  });
  _exports.camelize = camelize;
  _exports.capitalize = capitalize;
  _exports.classify = classify;
  _exports.dasherize = dasherize;
  _exports.decamelize = decamelize;
  _exports.htmlSafe = htmlSafe;
  _exports.isHTMLSafe = isHTMLSafe;
  _exports.underscore = underscore;
  _exports.w = w;
  /**
  This module is deprecated and should not be used.
  See [the deprecations guide](https://deprecations.emberjs.com/v4.x#toc_ember-string-from-ember-module)
  for more details.
  
  @module @ember/string
  */

  var STRING_DASHERIZE_REGEXP = /[ _]/g;
  var STRING_DASHERIZE_CACHE = new _utils.Cache(1000, key => decamelize(key).replace(STRING_DASHERIZE_REGEXP, '-'));
  var STRING_CAMELIZE_REGEXP_1 = /(-|_|\.|\s)+(.)?/g;
  var STRING_CAMELIZE_REGEXP_2 = /(^|\/)([A-Z])/g;
  var CAMELIZE_CACHE = new _utils.Cache(1000, key => key.replace(STRING_CAMELIZE_REGEXP_1, (_match, _separator, chr) => chr ? chr.toUpperCase() : '').replace(STRING_CAMELIZE_REGEXP_2, (match /*, separator, chr */) => match.toLowerCase()));
  var STRING_CLASSIFY_REGEXP_1 = /^(-|_)+(.)?/;
  var STRING_CLASSIFY_REGEXP_2 = /(.)(-|_|\.|\s)+(.)?/g;
  var STRING_CLASSIFY_REGEXP_3 = /(^|\/|\.)([a-z])/g;
  var CLASSIFY_CACHE = new _utils.Cache(1000, str => {
    var replace1 = (_match, _separator, chr) => chr ? `_${chr.toUpperCase()}` : '';
    var replace2 = (_match, initialChar, _separator, chr) => initialChar + (chr ? chr.toUpperCase() : '');
    var parts = str.split('/');
    for (var i = 0; i < parts.length; i++) {
      parts[i] = parts[i].replace(STRING_CLASSIFY_REGEXP_1, replace1).replace(STRING_CLASSIFY_REGEXP_2, replace2);
    }
    return parts.join('/').replace(STRING_CLASSIFY_REGEXP_3, (match /*, separator, chr */) => match.toUpperCase());
  });
  var STRING_UNDERSCORE_REGEXP_1 = /([a-z\d])([A-Z]+)/g;
  var STRING_UNDERSCORE_REGEXP_2 = /-|\s+/g;
  var UNDERSCORE_CACHE = new _utils.Cache(1000, str => str.replace(STRING_UNDERSCORE_REGEXP_1, '$1_$2').replace(STRING_UNDERSCORE_REGEXP_2, '_').toLowerCase());
  var STRING_CAPITALIZE_REGEXP = /(^|\/)([a-z\u00C0-\u024F])/g;
  var CAPITALIZE_CACHE = new _utils.Cache(1000, str => str.replace(STRING_CAPITALIZE_REGEXP, (match /*, separator, chr */) => match.toUpperCase()));
  var STRING_DECAMELIZE_REGEXP = /([a-z\d])([A-Z])/g;
  var DECAMELIZE_CACHE = new _utils.Cache(1000, str => str.replace(STRING_DECAMELIZE_REGEXP, '$1_$2').toLowerCase());
  /**
    Defines string helper methods including string formatting and localization.
  
    @class String
    @public
    @deprecated Add the package `@ember/string` to your project to use in place of this module.
  */
  /**
    Splits a string into separate units separated by spaces, eliminating any
    empty strings in the process.
  
    ```javascript
    import { w } from '@ember/string';
  
    w("alpha beta gamma").forEach(function(key) {
      console.log(key);
    });
  
    // > alpha
    // > beta
    // > gamma
    ```
  
    @method w
    @param {String} str The string to split
    @return {Array} array containing the split strings
    @public
    @deprecated Add `@ember/string` to your package.json
  */
  function w(str) {
    deprecateImportFromInternalString();
    return str.split(/\s+/);
  }
  /**
    Converts a camelized string into all lower case separated by underscores.
  
    ```javascript
    import { decamelize } from '@ember/string';
  
    decamelize('innerHTML');          // 'inner_html'
    decamelize('action_name');        // 'action_name'
    decamelize('css-class-name');     // 'css-class-name'
    decamelize('my favorite items');  // 'my favorite items'
    ```
  
    @method decamelize
    @param {String} str The string to decamelize.
    @return {String} the decamelized string.
    @public
    @deprecated Add `@ember/string` to your package.json
  */
  function decamelize(str) {
    deprecateImportFromInternalString();
    return DECAMELIZE_CACHE.get(str);
  }
  /**
    Replaces underscores, spaces, or camelCase with dashes.
  
    ```javascript
    import { dasherize } from '@ember/string';
  
    dasherize('innerHTML');                // 'inner-html'
    dasherize('action_name');              // 'action-name'
    dasherize('css-class-name');           // 'css-class-name'
    dasherize('my favorite items');        // 'my-favorite-items'
    dasherize('privateDocs/ownerInvoice';  // 'private-docs/owner-invoice'
    ```
  
    @method dasherize
    @param {String} str The string to dasherize.
    @return {String} the dasherized string.
    @public
    @deprecated Add `@ember/string` to your package.json
  */
  function dasherize(str) {
    deprecateImportFromInternalString();
    return STRING_DASHERIZE_CACHE.get(str);
  }
  /**
    Returns the lowerCamelCase form of a string.
  
    ```javascript
    import { camelize } from '@ember/string';
  
    camelize('innerHTML');                   // 'innerHTML'
    camelize('action_name');                 // 'actionName'
    camelize('css-class-name');              // 'cssClassName'
    camelize('my favorite items');           // 'myFavoriteItems'
    camelize('My Favorite Items');           // 'myFavoriteItems'
    camelize('private-docs/owner-invoice');  // 'privateDocs/ownerInvoice'
    ```
  
    @method camelize
    @param {String} str The string to camelize.
    @return {String} the camelized string.
    @public
    @deprecated Add `@ember/string` to your package.json
  */
  function camelize(str) {
    deprecateImportFromInternalString();
    return CAMELIZE_CACHE.get(str);
  }
  /**
    Returns the UpperCamelCase form of a string.
  
    ```javascript
    import { classify } from '@ember/string';
  
    classify('innerHTML');                   // 'InnerHTML'
    classify('action_name');                 // 'ActionName'
    classify('css-class-name');              // 'CssClassName'
    classify('my favorite items');           // 'MyFavoriteItems'
    classify('private-docs/owner-invoice');  // 'PrivateDocs/OwnerInvoice'
    ```
  
    @method classify
    @param {String} str the string to classify
    @return {String} the classified string
    @public
    @deprecated Add `@ember/string` to your package.json
  */
  function classify(str) {
    deprecateImportFromInternalString();
    return CLASSIFY_CACHE.get(str);
  }
  /**
    More general than decamelize. Returns the lower\_case\_and\_underscored
    form of a string.
  
    ```javascript
    import { underscore } from '@ember/string';
  
    underscore('innerHTML');                 // 'inner_html'
    underscore('action_name');               // 'action_name'
    underscore('css-class-name');            // 'css_class_name'
    underscore('my favorite items');         // 'my_favorite_items'
    underscore('privateDocs/ownerInvoice');  // 'private_docs/owner_invoice'
    ```
  
    @method underscore
    @param {String} str The string to underscore.
    @return {String} the underscored string.
    @public
    @deprecated Add `@ember/string` to your package.json
  */
  function underscore(str) {
    deprecateImportFromInternalString();
    return UNDERSCORE_CACHE.get(str);
  }
  /**
    Returns the Capitalized form of a string
  
    ```javascript
    import { capitalize } from '@ember/string';
  
    capitalize('innerHTML')                 // 'InnerHTML'
    capitalize('action_name')               // 'Action_name'
    capitalize('css-class-name')            // 'Css-class-name'
    capitalize('my favorite items')         // 'My favorite items'
    capitalize('privateDocs/ownerInvoice'); // 'PrivateDocs/ownerInvoice'
    ```
  
    @method capitalize
    @param {String} str The string to capitalize.
    @return {String} The capitalized string.
    @public
    @deprecated Add `@ember/string` to your package.json
  */
  function capitalize(str) {
    deprecateImportFromInternalString();
    return CAPITALIZE_CACHE.get(str);
  }
  function deprecateImportFromInternalString() {
    (true && !(false) && (0, _debug.deprecate)('Importing from `@ember/string` without having the `@ember/string` package in your project is deprecated. Please add `@ember/string` to your `package.json', false, {
      id: 'ember-string.add-package',
      for: 'ember-source',
      since: {
        available: '4.10',
        enabled: '4.10'
      },
      until: '5.0.0',
      url: 'https://deprecations.emberjs.com/v4.x/#toc_ember-string-add-package'
    }));
  }
  function deprecateImportFromString(name, message = `Importing ${name} from '@ember/string' is deprecated. Please import ${name} from '@ember/template' instead.`) {
    (true && !(false) && (0, _debug.deprecate)(message, false, {
      id: 'ember-string.htmlsafe-ishtmlsafe',
      for: 'ember-source',
      since: {
        available: '4.10',
        enabled: '4.10'
      },
      until: '5.0.0',
      url: 'https://deprecations.emberjs.com/v3.x/#toc_ember-string-htmlsafe-ishtmlsafe'
    }));
  }
  function htmlSafe(str) {
    deprecateImportFromString('htmlSafe');
    return (0, _glimmer.htmlSafe)(str);
  }
  function isHTMLSafe(str) {
    deprecateImportFromString('isHTMLSafe');
    return (0, _glimmer.isHTMLSafe)(str);
  }
});
