define("ember-initials/components/image/index", ["exports", "@ember/component", "@glimmer/tracking", "@ember/object", "@ember/application", "@ember/object/computed", "ember-initials/components/image/template"], function (_exports, _component, _tracking, _object, _application, _computed, _template) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer && (Object.defineProperty(i, e, a), a = null), a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  let ImageAvatarComponent = (_dec = (0, _computed.reads)('size'), _dec2 = (0, _computed.reads)('size'), _dec3 = (0, _computed.reads)('config.image.defaultImageUrl'), (_class = class ImageAvatarComponent extends _component.default {
    constructor(...args) {
      super(...args);
      _defineProperty(this, "layout", _template.default);
      _defineProperty(this, "tagName", '');
      _initializerDefineProperty(this, "image", _descriptor, this);
      _initializerDefineProperty(this, "size", _descriptor2, this);
      _initializerDefineProperty(this, "alt", _descriptor3, this);
      _initializerDefineProperty(this, "title", _descriptor4, this);
      _initializerDefineProperty(this, "height", _descriptor5, this);
      _initializerDefineProperty(this, "width", _descriptor6, this);
      _initializerDefineProperty(this, "defaultImage", _descriptor7, this);
      _initializerDefineProperty(this, "_src", _descriptor8, this);
    }
    get src() {
      return this._src || this.image || this.defaultImage;
    }
    set src(value) {
      return this._src = value;
    }
    get config() {
      return (0, _application.getOwner)(this).resolveRegistration('config:environment').emberInitials;
    }
    onError(e) {
      if (this.defaultImage && this.image !== this.defaultImage) {
        e.srcElement.src = this.defaultImage;
      }
    }
  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "image", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "size", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return 30;
    }
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "alt", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return 'User Avatar';
    }
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "title", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return 'User Avatar';
    }
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "height", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "width", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "defaultImage", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor8 = _applyDecoratedDescriptor(_class.prototype, "_src", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "onError", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "onError"), _class.prototype)), _class));
  var _default = _exports.default = ImageAvatarComponent;
});