define("ember-initials/components/initials/index", ["exports", "ember-initials/components/image", "@glimmer/tracking", "@ember/object", "@ember/object/computed", "@ember/polyfills", "ember-initials/utils/initials", "ember-initials/utils/store", "ember-initials/utils/color-index"], function (_exports, _image, _tracking, _object, _computed, _polyfills, _initials, _store, _colorIndex) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _dec4, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8, _descriptor9, _descriptor10, _descriptor11, _descriptor12, _descriptor13, _descriptor14, _descriptor15;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer && (Object.defineProperty(i, e, a), a = null), a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  const cache = new _store.default();
  const defaultColors = ['#1abc9c', '#16a085', '#f1c40f', '#f39c12', '#2ecc71', '#27ae60', '#e67e22', '#d35400', '#3498db', '#2980b9', '#e74c3c', '#c0392b', '#9b59b6', '#8e44ad', '#bdc3c7', '#34495e', '#2c3e50', '#95a5a6', '#7f8c8d', '#ec87bf', '#d870ad', '#f69785', '#9ba37e', '#b49255', '#b49255', '#a94136', '#5461b4'];
  let InitialsAvatarComponent = (_dec = (0, _computed.reads)('defaultName'), _dec2 = (0, _computed.reads)('name'), _dec3 = (0, _computed.reads)('name'), _dec4 = (0, _computed.reads)('name'), (_class = class InitialsAvatarComponent extends _image.default {
    constructor(...args) {
      super(...args);
      _initializerDefineProperty(this, "defaultBackground", _descriptor, this);
      _initializerDefineProperty(this, "fontSize", _descriptor2, this);
      _initializerDefineProperty(this, "fontWeight", _descriptor3, this);
      _initializerDefineProperty(this, "fontFamily", _descriptor4, this);
      _initializerDefineProperty(this, "textColor", _descriptor5, this);
      _initializerDefineProperty(this, "defaultName", _descriptor6, this);
      _initializerDefineProperty(this, "colors", _descriptor7, this);
      _initializerDefineProperty(this, "name", _descriptor8, this);
      _initializerDefineProperty(this, "alt", _descriptor9, this);
      _initializerDefineProperty(this, "title", _descriptor10, this);
      _initializerDefineProperty(this, "seedText", _descriptor11, this);
      _initializerDefineProperty(this, "_initials", _descriptor12, this);
      _initializerDefineProperty(this, "_textStyles", _descriptor13, this);
      _initializerDefineProperty(this, "_backgroundStyles", _descriptor14, this);
      _initializerDefineProperty(this, "_backgroundColor", _descriptor15, this);
    }
    get src() {
      return this._src || this.image || this._initialsSrc();
    }
    set src(value) {
      return this._src = value;
    }
    get initials() {
      return this._initials || (0, _initials.default)(this.name || this.defaultName);
    }
    set initials(value) {
      return this._initials = value;
    }
    get defaultTextStyles() {
      return {
        'font-family': this.fontFamily,
        'font-weight': this.fontWeight,
        'font-size': `${this.fontSize}px`
      };
    }
    get defaultBackgroundStyles() {
      return {
        'user-select': 'none',
        'vertical-align': 'middle',
        'background-color': this.backgroundColor
      };
    }
    get backgroundStyles() {
      return this._backgroundStyles || this.defaultBackgroundStyles;
    }
    set backgroundStyles(value) {
      return this._backgroundStyles = (0, _polyfills.assign)({}, this.defaultBackgroundStyles, value);
    }
    get textStyles() {
      return this._textStyles || this.defaultTextStyles;
    }
    set textStyles(value) {
      return this._textStyles = (0, _polyfills.assign)({}, this.defaultTextStyles, value);
    }
    get backgroundColor() {
      if (this._backgroundColor) return this._backgroundColor;
      const {
        colors,
        seedText,
        defaultName,
        defaultBackground
      } = this;
      if (seedText === defaultName) {
        return defaultBackground;
      }
      const index = (0, _colorIndex.default)(seedText, colors.length);
      return colors[index];
    }
    set backgroundColor(value) {
      return this._backgroundColor = value;
    }
    onError(e) {
      e.srcElement.src = this._initialsSrc();
    }
    _initialsSrc() {
      const properties = {
        initials: this.initials,
        initialsColor: this.textColor,
        textStyles: this.textStyles,
        backgroundStyles: this.backgroundStyles
      };
      return cache.getItem(properties);
    }
  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "defaultBackground", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return '#dd6a58';
    }
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "fontSize", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return 50;
    }
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "fontWeight", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return 200;
    }
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "fontFamily", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return 'Helvetica Neue Light, Arial, sans-serif';
    }
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "textColor", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return 'white';
    }
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "defaultName", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return '?';
    }
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "colors", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return defaultColors;
    }
  }), _descriptor8 = _applyDecoratedDescriptor(_class.prototype, "name", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor9 = _applyDecoratedDescriptor(_class.prototype, "alt", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor10 = _applyDecoratedDescriptor(_class.prototype, "title", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor11 = _applyDecoratedDescriptor(_class.prototype, "seedText", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor12 = _applyDecoratedDescriptor(_class.prototype, "_initials", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor13 = _applyDecoratedDescriptor(_class.prototype, "_textStyles", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor14 = _applyDecoratedDescriptor(_class.prototype, "_backgroundStyles", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor15 = _applyDecoratedDescriptor(_class.prototype, "_backgroundColor", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "onError", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "onError"), _class.prototype)), _class));
  var _default = _exports.default = InitialsAvatarComponent;
});