define("ember-content-editable-modifier/modifiers/content-editable", ["exports", "ember-modifier", "@ember/destroyable", "@ember/object"], function (_exports, _emberModifier, _destroyable, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _class;
  /* eslint-disable prettier/prettier */
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer && (Object.defineProperty(i, e, a), a = null), a; }
  function cleanup(instance) {
    instance.element?.removeEventListener('input', instance.domUpdated);
  }
  let ContentEditableModifier = _exports.default = (_class = class ContentEditableModifier extends _emberModifier.default {
    constructor(owner, args) {
      super(owner, args);
      _defineProperty(this, "didSetup", false);
      _defineProperty(this, "element", void 0);
      _defineProperty(this, "value", undefined);
      (0, _destroyable.registerDestructor)(this, cleanup);
    }
    modify(element, positional, named) {
      this.onChange = named.onChange;
      if (!this.didSetup) {
        this.element = element;
        if (named.placeholder) {
          element.setAttribute('placeholder', named.placeholder);
        }
        element.classList.add('ember-content-editable');
        element.addEventListener('input', this.domUpdated);
        if (named.autofocus) {
          element.focus();
        }
        this.didSetup = true;
      }
      if (this.value != named.value) {
        this.updateValue(named.value);
      }
      if (named.disabled && element.getAttribute('contenteditable')) {
        element.removeAttribute('contenteditable');
      } else if (!named.disabled && !element.getAttribute('contenteditable')) {
        element.setAttribute('contenteditable', 'true');
      }
    }
    updateValue(value) {
      this.value = value;
      if (this.value) {
        this.element.innerText = this.value;
      } else {
        this.element.innerText = '';
      }
    }
    domUpdated() {
      this.value = this.element.innerText;
      if (this.onChange) {
        this.onChange(this.value);
      }
    }
  }, (_applyDecoratedDescriptor(_class.prototype, "domUpdated", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "domUpdated"), _class.prototype)), _class);
});